import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import { Link } from "gatsby"

const NotFound = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Marsandi | Not Found</title>
      </Helmet>
      <section className="text-customgray">
        <div className="py-20 px-2 text-customgray h-full lg:h-4/6 text-center grid justify-items-center align-middle">
          <div className="w-full lg:w-5/12">
            <h1 className="text-3xl mb-10 lg:text-4xl text-customgray">
              Whoops!
            </h1>
            <h1 className="text-3xl lg:text-4xl text-customgray">
              You've wandered somewhere you're not supposed to
            </h1>
            <Link
              to="/"
              className="mx-2 md:mx-0 inline-block text-md px-4 py-4 leading-none rounded text-white bg-customblue mt-5"
            >
              Go Back
            </Link>
          </div>
        </div>
        <div className="bg-customdarkblue grid justify-items-center align-middle">
          <img
            src="/banner-img-1.png"
            alt="Banner"
            width="1920"
            height="auto"
          />
        </div>
        <Banner />
      </section>
    </Layout>
  )
}

export default NotFound
